var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"mt-4 table-shadow",attrs:{"headers":_vm.headers,"items":_vm.campanhas.data,"search":_vm.search,"sort-by":"data_cad","sort-desc":true,"loading":_vm.loading,"paginate":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Campanhas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('FilterCampanha',{on:{"selectedFilters":function($event){_vm.fetchCampanhas((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.nome_campanha",fn:function(ref){
var item = ref.item;
return [(Number(item.total_empresas) > 0)?[(Number(item.total_empresas) > 1)?_c('v-badge',{attrs:{"bordered":"","content":'+' + (Number(item.total_empresas) - 1)}},[_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" - "+_vm._s(item.apelido)+" ")]):[_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" - "+_vm._s(item.apelido)+" ")]]:[_vm._v(" Não há empresas vinculadas ")]]}},{key:"item.valor_custo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_custo))+" ")]}},{key:"item.perc_crescimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.perc_crescimento)+"% ")]}},{key:"item.nome_usuario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_usuario || "-")+" ")]}},{key:"item.data_cad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_cad))+" ")]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" - "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.aprovado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusCampanhaAprovação(item).color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusCampanhaAprovação(item).text)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.checkHistory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-timeline-check-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Linha do tempo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","icon":"","dark":"","small":""},on:{"click":function($event){return _vm.editarCampanha(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil ")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Avaliar Campanha")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","color":"green","small":""},on:{"click":function($event){return _vm.empresasParticipantes(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-domain")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Empresas Participantes ")])])]}}],null,true)}),_c('ModalEmpresasCampanha',{attrs:{"dialog":_vm.dialog,"empresas":_vm.listaEpresas},on:{"close":function($event){_vm.dialog = false}}}),_c('ModalTimeLine',{attrs:{"dialog":_vm.hasHistory,"historico":_vm.historicoAprocavao,"campanha":_vm.nomeCampanha},on:{"close":function($event){_vm.hasHistory = false}}}),(!_vm.campanhas.loading)?_c('div',{staticClass:"text-left pt-2"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }