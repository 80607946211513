<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>
        <v-icon class="mr-2">mdi-tune-variant</v-icon>
        Filtro
      </v-btn>
    </template>

    <v-card>
      <v-container>
        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresa"
          item-text="apelido"
          item-value="apelido"
          service="sistemaService.empresa"
          :filters="{
            calcula_comissao: 'S'
          }"
          v-model="filters.apelido"
          :isCompany="true"
          @change="handleFilter()"
        />
        <DatePickerMonth
          chips
          :currentDate="false"
          :dateValue="getStorage()"
          @date="assignDateFilter($event)"
        />
        <v-autocomplete
          prepend-inner-icon="mdi-list-status"
          label="Status"
          single-line
          :items="items"
          v-model="filters.id_status"
          item-text="text"
          item-value="id_status"
          clearable
          @change="handleFilter()"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <v-icon class="mr-2" :color="item.color">{{ item.icon }}</v-icon>
              {{ item.text }}
            </v-chip>
            <span v-if="index === 1" class="grey--text caption">
              (+{{ statusCampanha.length - 1 }}) <br />
            </span>
          </template>

          <template v-slot:item="{ item }">
            <v-icon class="mr-2" :color="item.color">{{ item.icon }}</v-icon>
            {{ item.text }}
          </template>
        </v-autocomplete>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import DatePickerMonth from "@/components/shared/DatePickerMonth";

export default {
  name: "FilterConsultaCampanhaAprovacao",

  components: {
    BaseFilter,
    DatePickerMonth
  },

  data() {
    return {
      teste: "",
      status: {
        name: "status",
        items: [],
        selected: "",
        preSelected: {}
      },
      filters: {
        ano_ref: new Date().getFullYear(),
        mes_ref: this.checkUtilDay(),
        id_status: ""
      },
      items: [
        {
          text: "Aguardando",
          color: "warning",
          icon: "mdi-timer-sand",
          id_status: 2
        },
        {
          text: "Aprovadas",
          color: "primary",
          icon: "mdi-thumb-up-outline",
          id_status: 1
        },

        {
          text: "Reprovadas",
          color: "red",
          icon: "mdi-thumb-down-outline",
          id_status: 3
        }
      ]
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
      this.setStorage();
    },
    assignDateFilter(date) {
      this.filters["ano_ref"] = date?.ano_ref;
      this.filters["mes_ref"] = date?.mes_ref;
      this.handleFilter();
    },
    handleStoragedFilter() {
      if (!this.getStorage()) this.setStorage();
      this.filters = this.getStorage();
      this.filters.id_status =
        this.items.find(el => el.id_status == this.filters.id_status)
          ?.id_status || 1;
    },
    getStorage() {
      return JSON.parse(localStorage.getItem("filters"));
    },
    setStorage() {
      localStorage.setItem("filters", JSON.stringify(this.filters));
    },
    checkUtilDay() {
      const data = new Date();
      if (data.getDate() == 1 && !this.filters?.id_status) {
        return data.getMonth();
      }
      return data.getMonth() + 1;
    }
  },

  mounted() {
    this.handleStoragedFilter();
    this.handleFilter();
  }
};
</script>
<style scoped></style>
