<template>
  <v-dialog v-model="dialog" persistent max-width="40%">
    <div class="table-empresas">
      <v-simple-table>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Empresas Participantes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon color="red">mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:default>
          <thead style="background-color: #d6d6d6">
            <tr>
              <th class="text-left">
                Bandeira
              </th>
              <th class="text-left">
                Empresa
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in empresas" :key="item.name">
              <td>
                <BaseSegmento
                  :segmento="item.id_band"
                  :label="item.descricao"
                />
              </td>
              <!-- <td>{{ item.apelido }}</td> -->
              <td>
                {{ item.nome_empresa | TitleCase | NomeEmpresa }}-
                {{ item.apelido }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-dialog>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento.vue";

export default {
  name: "ModalEmpresasCampanhas",

  components: {
    BaseSegmento
  },
  filters: {
    NomeEmpresa: value => value.replace(/-.+/, "")
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    empresas: {
      type: Array
    }
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Empresa", value: "nome_empresa" }
      ]
    };
  },

  watch: {
    dialog(value) {
      return value;
    }
  },

  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.table-empresas {
  background-color: #fff;
  padding: 10px;
}
.table-empresas .elevation-1 {
  box-shadow: 0px, 0px, 0px, 0px !important;
}
</style>
