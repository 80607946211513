<template>
  <div>
    <BaseTable
      :headers="headers"
      :items="campanhas.data"
      :search="search"
      sort-by="data_cad"
      :sort-desc="true"
      :loading="loading"
      :paginate="true"
      class="mt-4 table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Campanhas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            clearable
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <FilterCampanha
            @selectedFilters="fetchCampanhas((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.nome_campanha`]="{ item }">
        <template v-if="Number(item.total_empresas) > 0">
          <v-badge
            v-if="Number(item.total_empresas) > 1"
            bordered
            :content="'+' + (Number(item.total_empresas) - 1)"
          >
            {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
          </v-badge>
          <template v-else>
            {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
          </template>
        </template>
        <template v-else>
          Não há empresas vinculadas
        </template>
      </template>

      <template v-slot:[`item.valor_custo`]="{ item }">
        {{ item.valor_custo | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.perc_crescimento`]="{ item }">
        {{ item.perc_crescimento }}%
      </template>

      <template v-slot:[`item.nome_usuario`]="{ item }">
        {{ item.nome_usuario || "-" }}
      </template>

      <template v-slot:[`item.data_cad`]="{ item }">
        {{ item.data_cad | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }} -
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.aprovado`]="{ item }">
        <v-chip :color="statusCampanhaAprovação(item).color" dark>
          {{ statusCampanhaAprovação(item).text }}
        </v-chip>
      </template>

      <template v-can-access="24" v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              @click="checkHistory(item)"
              dark
              small
            >
              <v-icon small>mdi-timeline-check-outline</v-icon>
            </v-btn>
          </template>
          <span class="tooltip-small">Linha do tempo</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="ml-2"
              @click="editarCampanha(item)"
              color="primary"
              icon
              dark
              small
            >
              <v-icon small>mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span class="tooltip-small">Avaliar Campanha</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="ml-2"
              @click="empresasParticipantes(item)"
              color="green"
              small
            >
              <v-icon>mdi-domain</v-icon>
            </v-btn>
          </template>
          <span class="tooltip-small">Empresas Participantes </span>
        </v-tooltip>
      </template>
    </BaseTable>
    <ModalEmpresasCampanha
      :dialog="dialog"
      :empresas="listaEpresas"
      @close="dialog = false"
    />
    <ModalTimeLine
      :dialog="hasHistory"
      @close="hasHistory = false"
      :historico="historicoAprocavao"
      :campanha="nomeCampanha"
    />
    <div class="text-left pt-2" v-if="!campanhas.loading"></div>
  </div>
</template>

<script>
import serviceEmpresa from "@/services/empresa";
import serviceCampanha from "@/services/campanha";
import dateMixin from "../../mixins/dateMixin";
import currencyMixin from "../../mixins/currencyMixin";
import noDataMixin from "@/mixins/noDataMixin";

import BaseTable from "@/components/shared/NewBaseTable";
import FilterCampanha from "./FilterCampaign.vue";

import ModalEmpresasCampanha from "@/components/campanha/ModalEmpresasCampanha";
import ModalTimeLine from "./ModalTimeLine";

import campanha from "@/services/http/campanhaService";

import { mapMutations } from "vuex";

export default {
  name: "TabelaAprovacaoCampanhas",

  mixins: [dateMixin, currencyMixin, noDataMixin],

  components: {
    BaseTable,
    FilterCampanha,
    ModalEmpresasCampanha,
    ModalTimeLine
  },

  data() {
    return {
      headers: [
        { text: "ID", value: "id_campanha" },
        {
          text: "Campanha",
          align: "start",
          value: "nome_campanha"
        },
        { text: "Custo Estimado", value: "valor_custo", align: "right" },
        { text: "% Cresc.", value: "perc_crescimento", align: "right" },
        { text: "Aprovador", align: "center", value: "nome_usuario" },
        { text: "Criação", value: "data_cad", sortable: false },
        { text: "Período", value: "data_inicio" },
        { text: "Aprovação", value: "aprovado", sortable: false },
        { text: "Ações", value: "actions", sortable: false, align: "center" }
      ],

      campanhas: {
        loading: false,
        data: []
      },
      nomeCampanha: "",
      listaEpresas: [],
      dialog: false,
      search: "",
      loading: false,
      filters: {},
      hasHistory: false,

      historicoAprocavao: []
    };
  },

  async mounted() {
    this.setCurrentCampanha("");
  },

  methods: {
    ...mapMutations({
      setCurrentCampanha: "campanha/setCurrentCampanha"
    }),
    checkHistory(item) {
      this.nomeCampanha = item.nome_campanha;
      this.fetchApprovalHistory(item.id_campanha);
      this.hasHistory = this.historicoAprocavao == true ? true : false;
    },

    async fetchApprovalHistory(id) {
      let { data } = await campanha(id)
        .aprovacaoLog()
        .show();
      this.historicoAprocavao = data;
      this.hasHistory = true;
    },

    statusCampanhaAprovação(item) {
      let status = {
        text: "Aguardando Análise",
        color: "warning"
      };

      switch (item.id_status) {
        case "1":
          (status.text = "Aprovado"), (status.color = "green");
          break;
        case "2":
          (status.text = "Aguardando Análise"), (status.color = "warning");
          break;
        case "3":
          (status.text = "Reprovado"), (status.color = "red");
          break;
      }

      return status;
    },

    async fetchCampanhas(filter = {}) {
      this.loading = true;
      let { data } = await campanha()
        .aprovacao()
        .show({
          per_page: -1,
          status_campanha: "S",
          ...filter
        });
      this.loading = false;
      this.campanhas.data = data.data;
    },

    editarCampanha(item) {
      this.setCurrentCampanha(item.id_campanha);
      this.$router.push({
        path: `aprovacao/${item.id_campanha}`,
        params: {
          id_aprovacao: item.id_aprovacao,
          observacao: item.observacao
        }
      });
    },

    async empresasParticipantes(item) {
      try {
        const idCampanha = item.id_campanha;
        const empresasCampanhas = await serviceCampanha.getEmpresasParticipantes(
          {
            id_campanha: idCampanha
          }
        );
        const empresas = empresasCampanhas.data.data
          .map(item => item.id_empresa)
          .toString();

        const {
          data: { data }
        } = await serviceEmpresa.getEmpresas({ id_empresa: empresas });

        this.listaEpresas = data;
        this.dialog = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar as empresas"
        });
      }
    }
  }
};
</script>
<style scoped>
.tooltip-small {
  font-size: 0.8rem !important;
  padding: 0.1rem !important;
}
</style>
